import { makeStyles } from '@admitkard/ccl/style';
import React from 'react';
import RatingCard from './RatingCard';

const useStyles = makeStyles(
  (theme) => ({
    outerContainer: {
      width: '100%',
      boxSizing: 'border-box',
    },
    innerContainer: {
      color: theme.colorPalette.indigo.main,
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 400,
      backgroundColor: 'hsla(270, 100%, 97%, 1)',
      margin: '0 1rem',
      borderRadius: '1rem',
      padding: '0.25rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      '@media screen and (min-width: 992px)': {
        borderRadius: '1.75rem',
        flexDirection: 'row',
        padding: '0.75rem',
        margin: '0',
      },
    },
    ratingsContainer: {
      minWidth: '50%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '0.25rem',
      '@media screen and (min-width: 992px)': {
        gap: '0.75rem',
        justifyItems: 'stretch',
      },
    },
    textContainer: {
      display: 'flex',
      gap: '1rem',
      flexDirection: 'column',
      margin: '1rem',
      fontSize: '1rem',
      justifyContent: 'center',
      '@media screen and (min-width: 992px)': {
        width: 'min-content',
        gap: '2rem',
        margin: '3.5rem 2.5rem',
        fontSize: '1.5rem',
        minWidth: '15rem',
        lineHeight: '2.3125rem',
      },
    },
    cardButtonContainer: {
      zIndex: 1,
      height: '3rem',
      width: 'max-content',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '1rem 1rem 1rem 1.25rem',
      gap: '0.75rem',
      background: theme.colorPalette.indigo.main,
      borderRadius: '1.875rem',
      boxSizing: 'border-box',
      fontSize: '0.875rem',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      lineHeight: '1rem',
      fontWeight: 400,
      color: '#F7EFFF',
      border: 'none',
      textDecoration: 'none',
      '&:hover': {
        transform: 'translateY(-0.2rem)',
        boxShadow: '0px 3px 0px rgb(0 0 0 / 25%)',
      },
      '@media screen and (min-width: 992px)': {
        fontSize: '1.1rem',
        height: '3.5rem',
      },
    },
    cardButtonIconContainer: {
      height: '1.25rem',
      aspectRatio: '1',
      '@media screen and (min-width: 992px)': {
        height: '1.5rem',
      },
      '& svg': {
        height: 'inherit',
        width: 'inherit',
      },
    },
  }),
  { name: 'RatingSection' }
);

const RatingSection: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.outerContainer}>
      <div className={classes.innerContainer}>
        <div id='rating-cards' className={classes.ratingsContainer}>
          <RatingCard rating={4.6} label="Google Review" />
          <RatingCard rating={4.9} label="Facebook Review" />
        </div>
        <div className={classes.textContainer}>
          <span>trusted by over 10 lakh students globally</span>
          <div>
            <a id="cta-rating" href="/paf?journey=PROFILE_EVALUATION_URL" className={classes.cardButtonContainer}>
              Get started
              <span className={classes.cardButtonIconContainer}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="12" fill="#F7EFFF" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8425 11.8281C12.9207 11.75 12.9207 11.6234 12.8425 11.5453L9.69108 8.39379C9.49582 8.19853 9.49582 7.88195 9.69108 7.68668C9.88634 7.49142 10.2029 7.49142 10.3982 7.68668L14.0446 11.3331C14.2399 11.5284 14.2399 11.845 14.0446 12.0402L10.3982 15.6867C10.2029 15.8819 9.88634 15.8819 9.69108 15.6867C9.49582 15.4914 9.49581 15.1748 9.69108 14.9796L12.8425 11.8281Z"
                    fill="#483287"
                  />
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingSection;
