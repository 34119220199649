import { makeStyles } from '@admitkard/ccl/style';
import React from 'react';

interface RatingCardProps {
  rating: number;
  label: string;
}

const useStyles = (percentage: number) =>
  makeStyles(
    () => ({
      rootContainer: {
        backgroundColor: 'white',
        borderRadius: '0.75rem',
        display: 'flex',
        flexDirection: 'column',
        height: '8rem',
        padding: '1.5rem 1rem',
        '@media screen and (min-width: 992px)': {
          borderRadius: '1rem',
          height: '-webkit-fill-available',
          aspectRatio: '1/1',
          padding: '3rem 2rem',
        },
      },
      ratingValue: {
        fontSize: '2.5rem',
        letterSpacing: '-0.05em',
        '@media screen and (min-width: 992px)': {
          fontSize: '2.875rem',
        },
      },
      label: {
        fontSize: '1rem',
        width: 'min-content',
        '@media screen and (min-width: 992px)': {
          fontSize: '2rem',
        },
      },
      stars: {
        display: 'inline-block',
        fontSize: '1.125rem',
        lineHeight: '1',
        '&:before': {
          content: '"★★★★★"',
          letterSpacing: '0.25rem',
          background: `linear-gradient(90deg, hsla(266, 100%, 67%, 1) ${percentage}%, hsla(270, 100%, 97%, 1) ${percentage}%)`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
        '@media screen and (min-width: 992px)': {
          fontSize: '2rem',
        },
      },
      vSpace: {
        height: '1.5rem',
        '@media screen and (min-width: 992px)': {
          height: '4.25rem',
        },
      }
    }),
    { name: 'RatingCard' }
  );

const RatingCard: React.FunctionComponent<RatingCardProps> = (props) => {
  const percentageOfRating = (props.rating / 5) * 100;
  const classes = useStyles(percentageOfRating)();

  return (
    <div className={classes.rootContainer}>
      <span className={classes.ratingValue}>{props.rating}</span>
      <span className={classes.label}>{props.label}</span>
      <div className={classes.vSpace} />
      <div className={classes.stars}></div>
    </div>
  );
};

export default RatingCard;
